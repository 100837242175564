import styled from 'styled-components';
import { Image } from 'rebass';

export const ProjectImageLeft = styled(Image)`
  transform-origin: center;
  transform: translate(-40px);
  margin-top: 40px;
  margin-bottom: 20px;
  box-shadow: -10px -10px 20px rgba(255, 255, 255, 1),
    10px 10px 20px rgba(102, 119, 170, 0.1);

  @media only screen and (max-width: 830px) {
    transform: translate(0px) scale(1.25);
  }
`;

export const ProjectImageRight = styled(Image)`
  transform-origin: center;
  transform: translate(40px);
  margin-top: 40px;
  margin-bottom: 20px;
  box-shadow: -10px -10px 20px rgba(255, 255, 255, 1),
    10px 10px 20px rgba(102, 119, 170, 0.1);

  @media only screen and (max-width: 830px) {
    transform: translate(0px) scale(1.25);
  }
`;

export const ProjectImageTransparent = styled(Image)`
  transform-origin: center;
  transform: translate(0px) scale(1.1);
  margin-top: 40px;
  margin-bottom: 20px;
`;

export default ProjectImageTransparent;