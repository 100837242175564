import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text, Button as ButtonRebass } from 'rebass';

const textStyle = {
  padding: 5
};

const containerStyle = {
  cursor: 'pointer'
};

function MyButton(props) {
  const { label } = props

  return (
    <Button style={containerStyle}>
      <Text style={textStyle}>{label}</Text>
    </Button>
  )
}

export default MyButton 

MyButton.propTypes = {
  label: PropTypes.string.isRequired
};



const Button = styled(ButtonRebass).attrs({
  bg: 'primary',
  boxShadow: 0,
  borderRadius: 25,
  fontFamily: 'Barlow Condensed',
  letterSpacing: '0.1rem'
})`
  background-image: linear-gradient(to bottom right , rgba(2, 200, 200, 0.1), rgba(2, 200, 200, 1));
  position: relative;
  box-shadow: 0 8px 8px rgba(55, 84, 170, 0.2);
  transition: all 0.25s;
  top: 0;
  

  &:hover {
    top: -10px;
    box-shadow: 0 12px 16px rgba(55, 84, 170, 0.25);
  }
`;
