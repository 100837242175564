import React from 'react';
import styled from 'styled-components';
import { Section } from 'react-scroll-section';
import { Heading } from 'rebass';
import PropTypes from 'prop-types';
import Slide from 'react-reveal/Slide';

const SectionContainer = styled.div`
  min-height: 100vh;
  min-width: 320px;
  max-width: 1366px;
  display: flex;
  margin: auto;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  padding: 5em 1em;
  scroll-behavior: smooth;

  @media only screen and (max-width: 700px) {
    min-height: 80vh;
  }
`;

const SectionHead = styled(Heading)`
  font-size: 42px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`;

const DefaultBackground = () => <div />;

const Container = ({ id, children, Background = DefaultBackground }) => (
  <Section id={id} style={{ position: 'relative' }}>
    <Background />
    <SectionContainer>{children}</SectionContainer>
  </Section>
);

Container.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  Background: PropTypes.func,
};

const Header = ({ name }) => (
  <Slide left>
    <SectionHead color="black" mb={4}>
      {name}
    </SectionHead>
  </Slide>
);

Header.propTypes = {
  name: PropTypes.string.isRequired
};

export default {
  Container,
  Header,
};
