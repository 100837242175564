import styled from 'styled-components';
import { Text } from 'rebass';

const ProjectTitle = styled(Text)`
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export default ProjectTitle;