module.exports = {
  background: '#F0F0F0',
  backgroundDark: '#F0F0F0',

  primary: '#42D9A9',
  primaryLight: '#59FFCA',
  primaryDark: '#2DB388',

  secondary: '#3ECCC7',
  secondaryLight: '#55F2ED',
  secondaryDark: '#29A6A1',

  tertiary: '#F6F6F6', // Boxes & Background
  tertiaryLight: '#FFFFFF', // negativ Text
  tertiaryDark: '#F0F0F0', // Header & Footer

  dark: '#4B4B4B', // Text
  totalDark: '#000000', // Text
  shadowSoft: '6677AA',
  shadowHard: '3754AA'
};
