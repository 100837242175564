import React from 'react';
import Headroom from 'react-headroom';
import { Link } from 'gatsby';
import { Text, Flex, Image } from 'rebass';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import RouteLink from './RouteLink';
import Logo from './Logo/JohnTheHuman.png';

const HeaderContainer = styled(Headroom)`
  .headroom--pinned {
    background: ${props => props.theme.colors.tertiaryDark};   
    box-shadow: 0 20px 40px 0 rgba(102, 119, 170, 0.15);
    border-bottom: 1px solid ${props => props.theme.colors.tertiaryLight};
  }

  background: 'none';
  position: absolute;
  width: 100%;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #4b4b4b;
`;

const ProjectHeader = () => (
  <HeaderContainer>
    <Fade top>
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <StyledLink to="/">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Image
              src={Logo}
              alt="Portfolio Logo"
              style={{
                cursor: 'pointer',
                width: "50px",
                height: "50px"
              }}
            />
            <Text
              fontSize={[2, 3]}
              style={{
                marginLeft: '10px',
                marginTop: 'auto',
                marginBottom: 'auto',
                cursor: 'pointer',
              }}
            >
              John The Human
            </Text>
          </div>
        </StyledLink>

        <Flex mr={[0, 3, 5]}>
          <StyledLink to="/#service">
            <RouteLink name="Service" />
          </StyledLink>
          <StyledLink to="/#Projects">
            <RouteLink name="Projects" />
          </StyledLink>
          <StyledLink to="/#about">
            <RouteLink name="About" />
          </StyledLink>
        </Flex>
      </Flex>
    </Fade>
  </HeaderContainer>
);

export default ProjectHeader;
