import styled from 'styled-components';
import { Heading } from 'rebass';

export const Hero = styled(Heading)`
  text-shadow: 0em 0em 1em rgb(246, 246, 246);
`;

export const Subheading = styled(Heading)`
  text-shadow: 0em 0em 1em rgb(246, 246, 246);
`;
