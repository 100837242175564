import React from 'react';
import { Link } from 'gatsby';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import MyButton from '../components/MyButton';

const ButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
`;

const SeeAllProjects = () => {
  return (
    <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
      <ButtonContainer width={[1]} px={[1, 2, 4]}>
        <Fade bottom>
          <Link to="/#Projects">
            <MyButton label="see all projects 👀" />
          </Link>
        </Fade>
      </ButtonContainer>
    </Flex>
  );
};

export default SeeAllProjects;
