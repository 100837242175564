import styled from 'styled-components';
import { Card as CardRebass } from 'rebass';

export const ProjectContentCard = styled(CardRebass).attrs({
  zIndex: -5,
  margin: '5%',
  marginTop: -20,
})`
  @media only screen and (min-width: 830px) {
    position: relative;
    background: #F6F6F6;

    border-radius: 25px;
    border-width: 1px;
    border-style: solid;
    border-color: #FAFCFF;
    
    box-shadow: -10px -10px 20px rgba(255, 255, 255, 1),
      10px 10px 20px rgba(102, 119, 170, 0.1);
    transition: all 0.25s;
    top: 0;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 40px;
    cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  }
`;

export default ProjectContentCard;
