import React from 'react';
import styled from 'styled-components';
import { Text, Box, Link, Flex } from 'rebass';
import Fade from 'react-reveal/Fade';

const FooterContainer = styled.div`
  min-width: 320px;
  max-width: 1366px;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
`;

const FooterLink = styled(Link)`
  text-decoration: none;
`;

const TextFooter = styled(Text)`
  color: ${props => props.theme.colors.totaldark};

  & a {
    color: ${props => props.theme.colors.totalDark};
  }
`;

const Footer = () => {
  return (
    <Box p={3} backgroundColor="tertiaryDark" as="footer">
      <FooterContainer>
        <Fade left>
          <TextFooter fontSize={[1, 2]} ml={[1, 2, 4]}>
            <FooterLink href="/Imprint" mr={3}>Imprint</FooterLink>
            <FooterLink href="/Privacy" mr={3}>Privacy</FooterLink>
            <FooterLink href="mailto:office@johnthehuman.com" mr={3}>Mail</FooterLink>
          </TextFooter>
        </Fade>
        <Flex>
          <Fade right>
            <Text mr={[1, 2, 4]}>© 2020 Jonathan Stütz</Text>
          </Fade>
        </Flex>
      </FooterContainer>
    </Box>
  );
};

export default Footer;


